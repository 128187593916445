<template>
    <components
        :is="curComp"
        :params="params"
        :curCom="curCom"
        @compSwitch="handleCompSwitch"
        @changeCurCom="changeCurCom"
    ></components>
</template>

<script>
import LoginPageManagementScrollWrapper from "@/components/scrollWrapper/LoginPageManagement.vue";

export default {
    name: 'LoginPageManagement',
    components: {
        LoginPageManagementScrollWrapper,
        LoginPageTemplateScrollWrapper: () => import(/* webpackChunkName:'LoginPageTemplateScrollWrapper' */"@/components/scrollWrapper/LoginPageTemplate.vue")
    },
    data() {
        return {
            curComp: 'LoginPageManagementScrollWrapper',
            comps: {
                'list': 'LoginPageManagementScrollWrapper',
                'template': 'LoginPageTemplateScrollWrapper'
            },
            params: {},
            curCom: null
        };
    },
    methods: {
        handleCompSwitch(comp, params) {
            this.changeCurCom('template');
            this.params = params;
            this.curComp = this.comps[comp]
        },
        changeCurCom (val) {
            this.curCom = val;
        }
    }
}
</script>
